import { get } from 'svelte/store'
import { navigate } from 'svelte-routing'

import { HTTP_STATUS_UNAUTHORIZED, ISO_LENGTH } from '@/config/constants'

import { _ } from '@/helpers/i18n'
import { notifier } from '@/helpers/notifier'

import { apiCall } from './apiCall'

const MAX_ERROR_TEXT_LENGTH = 200

export const availableLevels = (maxLevel: string): string[] => {
  const allLevels = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2']
  const index = allLevels.indexOf(maxLevel)

  return allLevels.slice(0, index + 1)
}

const loginRequiredForPath = (path: string) => {
  // todo - improve this function, before it was just `!path.includes('login')`
  const segment = path.split('/')
  return segment[1].length === ISO_LENGTH || ['profile', 'stats', 'search-users', 'rang-list'].includes(segment[1])
}

export const fetchData = async (url: string, data: any = {}, errorMessage = 'failed to fetch data') => {
  const res = await apiCall(url, data)
  const returnData = await res.json()
  if (!('status' in res)) {
    console.error({
      data,
      url
    }, errorMessage + ' ReturnData: ' + JSON.stringify(returnData))
    return
  }
  if (res.status === HTTP_STATUS_UNAUTHORIZED) {
    if (returnData?.error === 'mix.page401Redirect' && loginRequiredForPath(location.pathname)) {
      const redirectLocation = '/login?redirect=' + location.href
      navigate(redirectLocation)
    }
    return new Promise(() => {
      return { error: 'Access to this page is not allowed' }
    })
  }
  if (returnData.error) { // if element error exist we will return it
    if ([
      'testsPage.emptyUserVocabulary',
      'tasksPage.cantVoteOwnCorrection',
      'testsPage.notAllowedIKnowThis'
    ].includes(returnData.error)) {
      return returnData
    } else {
      notifier.error(get(_)(returnData.error))
      throw new Error(`${errorMessage} url: ${url} data:${JSON.stringify(data).substring(0, MAX_ERROR_TEXT_LENGTH)} 
      ReturnData: ${JSON.stringify(returnData).substring(0, MAX_ERROR_TEXT_LENGTH)}`)
    }
  }

  if (res.ok) {
    return returnData
  }
}
